import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ModifyTripDetailsActions } from '@pnd-store/.';
import { DispatcherTripsStoreActions, DispatcherTripsStoreSelectors } from '@pnd-store/dispatcher-trips-store';
import { GlobalFilterStoreSelectors } from '@pnd-store/global-filters-store';
import { PndStore } from '@pnd-store/pnd-store';
import { TripsStoreActions } from '@pnd-store/trips-store';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { Unsubscriber, XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoLtlDriverContact, XpoLtlMessagingDialogService } from '@xpo-ltl/ngx-ltl-messaging';
import { CarrierMaster, CarrierMasterLocation } from '@xpo-ltl/sdk-carriermanagement';
import {
  CityOperationsApiService,
  DispatchTrip,
  PnDTrip,
  RouteDetail,
  RouteSummary,
  Stop,
  Trip,
  TripDetail,
  TripDriver,
  TripEquipment,
  TripNode,
  TripSummary,
  UpdatePnDTripResp,
  UpdatePnDTripRqst,
} from '@xpo-ltl/sdk-cityoperations';
import {
  CarrierTenderGroupStatusCd,
  CmsTripTypeCd,
  NodeTypeCd,
  RouteCategoryCd,
  TripEquipmentStatusCd,
  TripNodeActivityCd,
  TripNodeStatusCd,
  TripNodeTypeCd,
  TripStatusCd,
} from '@xpo-ltl/sdk-common';
import { Equipment, TrailerLoad } from '@xpo-ltl/sdk-dockoperations';

import { DispatcherTripsGridItem } from 'app/inbound-planning/components/dispatcher-trips/models/dispatcher-trips-grid-item.model';
import { TripPlanningGridItem } from 'app/inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import { TripFormFields } from 'app/inbound-planning/components/trips/create-trip/enums/trip-form-fields';
import { TripsGridItemConverterService } from 'app/inbound-planning/shared';
import { CarriersSelectorDialogComponent } from 'app/inbound-planning/shared/components/carriers-selector-dialog/carriers-selector-dialog.component';
import { GridToolbarService } from 'app/inbound-planning/shared/components/grid-toolbar/services/grid-toolbar.service';
import { PdoEquipmentStatusPipe } from 'app/inbound-planning/shared/pipes/pdo-equipment-status.pipe';
import { CartageService } from 'app/inbound-planning/shared/services/cartage.service';
import { DispatchTripsGridItemConverterService } from 'app/inbound-planning/shared/services/dispatch-trips-grid-item-converter.service/dispatch-trips-grid-item-converter.service';
import { DispatcherTripsService } from 'app/inbound-planning/shared/services/dispatcher-trips.service';
import { TripsService } from 'app/inbound-planning/shared/services/trips.service';
import { ConfirmCancelAction } from 'core/dialogs/confirm-cancel/confirm-cancel-action.enum';
import {
  capitalize as _capitalize,
  first as _first,
  forEach as _forEach,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  isFunction as _isFunction,
  sortBy as _sortBy,
  size as _size,
} from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, Subject, Subscriber } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LayoutPreferenceService } from 'shared/layout-manager';
import { PndRouteUtils } from 'shared/route-utils';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../../core';
import { ConfirmCancelData } from '../../../../../core/dialogs/confirm-cancel/confirm-cancel-data';
import { ConfirmCancelComponent } from '../../../../../core/dialogs/confirm-cancel/confirm-cancel.component';
import { PndMultiGridBoardViewService } from '../../../../../shared/classes/pnd-multi-grid-board-view.service';
import { PndStoreState } from '../../../../store';
import { AutoCompleteItem } from '../../../shared/components/autocomplete/autocomplete.component';
import { SicZonesAndSatellites } from '../../../shared/models/sic-zones-and-satellites.model';
import { AssignShipmentsService } from '../../assign-shipments/services/assign-shipments.service';
import { FormModeEnum } from '../create-trip/enums/form-mode.enum';
import { CreateTripService } from '../create-trip/services/create-trip.service';
import { TripManagerBaseComponent } from '../trip-manager-base.component';
import { DriverContactsService } from './../../../shared/services/driver-contacts.service';
import {
  BreadcrumbsOptions,
  TripBreadcrumbsLayerHelperService,
} from './../../planning-map/layers/routes-layer/services/helpers/trip-breadcrumbs-layer-helper.service';
import { TripRenderingService } from './../../planning-map/layers/routes-layer/services/trip-rendering.service';
import { DispatchTripButtonValidation } from './enums/dispatch-trip-validationmessages.enum';
import { ModifyTripDetailsBoard } from './enums/modify-trip-details-board-type.enum';
import { ModifyTripDetailsPanel } from './enums/modify-trip-details-panel.enum';
import { ModifyTripDetailsActionConfig } from './models/modify-trip-details-action-config.model';
import { ModifyTripDetailsComponentName } from './modify-trip-details-component-name';
import { ModifyTripDetailsService } from './services/modify-trip-details.service';

export enum BreadcrumbsTooltipText {
  SHOW_BREADCRUMBS = 'Show Breadcrumbs',
  HIDE_BREADCRUMBS = 'Hide Breadcrumbs',
}

@Component({
  selector: 'pnd-modify-trip-details',
  templateUrl: './modify-trip-details.component.html',
  styleUrls: ['./modify-trip-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PndMultiGridBoardViewService, GridToolbarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyTripDetailsComponent extends TripManagerBaseComponent implements OnInit, OnDestroy {
  private readonly headerActionsSubject = new BehaviorSubject<ModifyTripDetailsActionConfig[]>([]);
  readonly headerActions$ = this.headerActionsSubject.asObservable();

  private messagesButtonClickSubject = new Subject<MouseEvent>();
  readonly messagesButtonClick$ = this.messagesButtonClickSubject.asObservable();

  private returnTripButtonClickSubject = new Subject<void>();
  readonly returnTripButtonClick$ = this.returnTripButtonClickSubject.asObservable();

  private completeTripButtonClickSubject = new Subject<void>();
  readonly completeTripButtonClick$ = this.completeTripButtonClickSubject.asObservable();

  private showBreadCrumbsButtonClickSubject = new Subject<void>();
  readonly showBreadCrumbsButtonClick$ = this.showBreadCrumbsButtonClickSubject.asObservable();

  private convertToCartageButtonClickSubject = new Subject<void>();
  readonly convertToCartageButtonClick$ = this.convertToCartageButtonClickSubject.asObservable();

  private deleteButtonClickSubject = new Subject<void>();
  readonly deleteButtonClick$ = this.deleteButtonClickSubject.asObservable();

  private dispatchButtonClickSubject = new Subject<void>();
  readonly dispatchButtonClick$ = this.dispatchButtonClickSubject.asObservable();

  private isProcessingSubject = new BehaviorSubject<boolean>(false);
  readonly isProcessing$ = this.isProcessingSubject.asObservable();

  showTripDetailsSection$: Observable<boolean> = this.modifyTripDetailsService.showTripDetailsSection$;

  previousSavedTractorExist: boolean;

  showRoutesSection: boolean = true;
  tripStatus: TripStatusCd;
  routesGridReady$: Observable<boolean>;
  initialFormState: Map<string, AutoCompleteItem | { [key: string]: any }>;
  tripsSource: DispatchTrip[] | TripDetail[];

  readonly FormMode = FormModeEnum;
  readonly TripStatusCd = TripStatusCd;
  readonly ModifyTripDetailsBoard = ModifyTripDetailsBoard;
  readonly BreadcrumbsTooltipText = BreadcrumbsTooltipText;
  readonly trip$ = this.modifyTripDetailsService.trip$.pipe(
    filter((trip) => !!trip),
    takeUntil(this.unsubscriber.done$)
  );
  readonly supplementalTripDetailInfo$ = this.modifyTripDetailsService.supplementalTripDetails$.pipe(
    filter((trip) => !!trip),
    takeUntil(this.unsubscriber.done$)
  );

  readonly tripSummary$ = this.modifyTripDetailsService.tripSummary$;
  readonly selectedBoard$ = this.multiGridBoardService.selectedBoard$;
  readonly error$ = this.modifyTripDetailsService.error$;
  actualStartTime: Date;
  actualCompleteTime: Date;
  driverLabel: string;

  private readonly deliveryCountMoreThanZeroSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly deliveryCountMoreThanZero$ = this.deliveryCountMoreThanZeroSubject.asObservable();

  private readonly atLeastOneRouteExistSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly atLeastOneRouteExist$ = this.atLeastOneRouteExistSubject.asObservable();

  isDeleteButtonVisible$: Observable<boolean>;
  isDeleteButtonDisabled$: Observable<boolean>;

  isConvertToCartageButtonEnabled$: Observable<boolean>;

  breadcrumbsButtonVisible$: Observable<boolean> = of(false);

  messageSate$: Observable<boolean> = of(false);

  isDispatchButtonEnabled$: Observable<boolean>;
  private isReturnButtonDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isReturnTripButtonDisabled$ = this.isReturnButtonDisabledSubject.asObservable();

  driverMessageCount$: Observable<number>;
  driverMessageIcon$: Observable<string>;

  sics$: Observable<AutoCompleteItem[]>;

  breadcrumbsText: string = '';

  convertToCartageButtonText: string = '';

  dispatchTripButtonValidationMessage: string = '';
  dispatchTripButtonValidationMessages: string[] = [];

  straightTruckControl: AbstractControl;
  estStartTimeControl: AbstractControl;
  estClearTimeControl: AbstractControl;
  estCompleteTimeControl: AbstractControl;
  driverControl: AbstractControl;
  tractorControl: AbstractControl;
  routesAndEquipmmentControl: AbstractControl;

  activePanel: ModifyTripDetailsPanel;
  tripDetailsPanels = ModifyTripDetailsPanel;

  @Input()
  set headerActions(value: ModifyTripDetailsActionConfig[]) {
    this.headerActionsSubject.next(value);
  }

  constructor(
    private modifyTripDetailsService: ModifyTripDetailsService,
    private multiGridBoardService: PndMultiGridBoardViewService,
    private cityOperationsService: CityOperationsApiService,
    private notificationMessageService: NotificationMessageService,
    private dialog: MatDialog,
    private layoutPreferenceService: LayoutPreferenceService,
    private tripsService: TripsService,
    pndStore$: PndStore<PndStoreState.State>,
    createTripService: CreateTripService,
    formBuilder: UntypedFormBuilder,
    validationService: FormatValidationService,
    protected timeService: XpoLtlTimeService,
    changeDetectorRef: ChangeDetectorRef,
    private dispatcherTripsService: DispatcherTripsService,
    private dispatchTripsGridItemConverterService: DispatchTripsGridItemConverterService,
    private tripsGridItemConverterService: TripsGridItemConverterService,
    private cartageService: CartageService,
    private tripRenderingService: TripRenderingService,
    private tripBreadcrumbsLayerHelperService: TripBreadcrumbsLayerHelperService,
    private driversContactService: DriverContactsService,
    private pdoEquipmentStatusPipe: PdoEquipmentStatusPipe,
    private serviceCentersService: XpoLtlServiceCentersService,
    private messagingDialog: XpoLtlMessagingDialogService,
    protected confirmDialog: XpoConfirmDialog,
    private assignShipmentsService: AssignShipmentsService

  ) {
    super(pndStore$, createTripService, formBuilder, validationService, timeService, changeDetectorRef);

    this.driverMessageCount$ = combineLatest([this.trip$, this.driversContactService.driverContacts$]).pipe(
      map(([trip, contacts]: [TripDetail | DispatchTrip, XpoLtlDriverContact[]]) => {
        const driverEmpId = this.dsrEmployeeIdFromTrip(trip);
        const contact = contacts?.find((c) => c.dsrEmplId === driverEmpId);
        return contact?.unreadMessages ?? 0;
      })
    );
    this.driverMessageIcon$ = this.driverMessageCount$.pipe(map((count) => (count > 0 ? 'messageUnread' : 'message')));
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.activePanel === ModifyTripDetailsPanel.ASSIGN_DROP) {
        this.modifyTripDetailsService.closeAssignDropPanel();
      } else {
        this.close(true);
      }
    }
  }
  trackHeaderActionBy(index, headerAction: ModifyTripDetailsActionConfig): string | null {
    if (!headerAction) {
      return null;
    }
    return headerAction?.nameAction + '-' + headerAction?.tooltip;
  }
  toogleShowTripDetailsSection() {
    this.modifyTripDetailsService.toggleShowTripDetailsSection(
      !this.modifyTripDetailsService.getShowTripDetailsSection()
    );
  }
  showSaveChangesModal(callback?: () => void, closeOnCompleteAction?: boolean): void {
    const confirmCancelData: ConfirmCancelData = new ConfirmCancelData(
      'Do you want to save your changes?',
      '<p>You have unsaved information on this page. Leaving without saving will remove all changes.</p>',
      'DO NOT SAVE',
      'SAVE',
      'CANCEL'
    );

    const dialogRef: MatDialogRef<ConfirmCancelComponent, ConfirmCancelAction> = this.dialog.open(
      ConfirmCancelComponent,
      {
        data: confirmCancelData,
        disableClose: true,
        width: '620px',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((action: ConfirmCancelAction) => {
        if (action === ConfirmCancelAction.CONFIRM) {
          this.update$()
            .pipe(take(1), takeUntil(this.unsubscriber.done$))
            .subscribe(
              () => {
                if (callback) {
                  callback();
                }

                if (closeOnCompleteAction) {
                  this.close(false);
                }
              },
              (error) => {}
            );
        } else if (action === ConfirmCancelAction.CANCEL) {
          if (callback) {
            callback();
          }

          if (closeOnCompleteAction) {
            this.close(false);
          }
        }
      });
  }

  close(blockIfThereArePendingChanges: boolean): void {
    if (blockIfThereArePendingChanges && this.pendingChanges()) {
      this.showSaveChangesModal(() => {}, true);
    } else {
      const closeAction = this.headerActionsSubject.value.find((action) => action.nameAction === 'close');

      if (closeAction && _isFunction(closeAction.callbackFn)) {
        closeAction.callbackFn();
      }
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this.tripsSource = this.layoutPreferenceService.isDispatcherLayout()
      ? this.dispatcherTripsService.trips
      : this.tripsService.trips;

    this.formGroup.disable();
    this.showSpinnerSubject.next(true);

    this.trip$.pipe(take(1)).subscribe((trip) => {
      let statusCode: string;
      if ((trip as DispatchTrip)?.tripInstId) {
        const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
        statusCode = data?.tripStatusCd;
      } else {
        const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
        statusCode = data?.statusCd;
      }

      if (statusCode === TripStatusCd.NEW_TRIP) {
        this.multiGridBoardService.setSelectedBoard(ModifyTripDetailsBoard.Shipments);
        this.assignShipmentsService.setSelectedBoard(ModifyTripDetailsBoard.Shipments);
      } else {
        this.multiGridBoardService.setSelectedBoard(ModifyTripDetailsBoard.Stops);
        this.assignShipmentsService.setSelectedBoard(ModifyTripDetailsBoard.Stops);

      }
    });

    this.multiGridBoardService.loadMultiGridBoardViews(ModifyTripDetailsComponentName);

    this.routesGridReady$ = combineLatest([
      this.modifyTripDetailsService.tripSummary$,
      this.modifyTripDetailsService.isLoading$,
    ]).pipe(
      map(([summary, loadingTripSummary]: [TripSummary, boolean]) => {
        return !!summary && !loadingTripSummary;
      }),
      takeUntil(this.unsubscriber.done$)
    );
    this.subscribeToTripChanges();
    this.updateTheStateOfConvertToCartageButton();

    this.updateStateOfDeleteButton();
    this.updateStateOfBreadcrumbsButton();
    this.updateStateOfMessageButton();

    this.straightTruckControl = this.formGroup.get(TripFormFields.IS_STRAIGHT_TRUCK);
    this.estStartTimeControl = this.formGroup.get(TripFormFields.EST_START);
    this.estClearTimeControl = this.formGroup.get(TripFormFields.EST_CLEAR);
    this.estCompleteTimeControl = this.formGroup.get(TripFormFields.EST_COMPLETE);
    this.driverControl = this.formGroup.get(TripFormFields.DRIVER);
    this.tractorControl = this.formGroup.get(TripFormFields.TRACTOR);
    this.routesAndEquipmmentControl = this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT);

    // Dispatch button should only be enabled if the trip is NEW and no API activity happening
    this.isDispatchButtonEnabled$ = this.trip$.pipe(
      map((trip) => {
        let statusCode: string;
        if ((trip as DispatchTrip)?.tripInstId) {
          const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
          statusCode = data?.tripStatusCd;
        } else {
          const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
          statusCode = data?.statusCd;
        }
        return statusCode === TripStatusCd.NEW_TRIP;
      }),
      withLatestFrom(this.showSpinner$),
      map(([tripStatusNew, isLoading]: [boolean, boolean]) => tripStatusNew && !isLoading)
    );

    this.messagesButtonClick$
      .pipe(
        debounceTime(500),
        withLatestFrom(this.trip$),
        withLatestFrom(this.driversContactService.driverContacts$),
        map(([[event, trip], contacts]) => {
          const driverEmpId = this.dsrEmployeeIdFromTrip(trip);
          const contact = contacts?.find((c) => c.dsrEmplId === driverEmpId);
          return { contact, event };
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(({ contact, event }) => {
        this.openMessageDialog(contact, event);
      });

    this.returnTripButtonClick$
      .pipe(debounceTime(500), withLatestFrom(this.trip$), takeUntil(this.unsubscriber.done$))
      .subscribe(([_, trip]) => {
        this.returnTripButtonClicked(trip);
      });

    this.completeTripButtonClick$
      .pipe(debounceTime(500), withLatestFrom(this.trip$), takeUntil(this.unsubscriber.done$))
      .subscribe(([_, trip]) => {
        this.completeTripButtonClicked(trip);
      });

    this.showBreadCrumbsButtonClick$
      .pipe(debounceTime(500), withLatestFrom(this.trip$), takeUntil(this.unsubscriber.done$))
      .subscribe(([_, trip]) => {
        this.showBreadcrumbsButtonClicked(trip);
      });

    this.convertToCartageButtonClick$
      .pipe(debounceTime(500), withLatestFrom(this.trip$), takeUntil(this.unsubscriber.done$))
      .subscribe(([_, trip]) => {
        this.convertToCartageButtonClicked(trip);
      });

    this.deleteButtonClick$
      .pipe(debounceTime(500), withLatestFrom(this.trip$), takeUntil(this.unsubscriber.done$))
      .subscribe(([_, trip]) => {
        this.deleteButtonClicked(trip);
      });

    this.dispatchButtonClick$.pipe(debounceTime(500), withLatestFrom(this.trip$)).subscribe(([_, trip]) => {
      this.dispatchButtonClicked(trip);
    });

    this.cartageService.convertToCartageSuccess$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((success) => {
      this.convertToCartageButtonText = success ? 'Change Carrier' : 'Convert to Cartage';
    });

    this.modifyTripDetailsService.navigationPanel$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((panel: ModifyTripDetailsPanel) => {
        this.activePanel = panel;
      });
  }

  /**
   * Return the dsrEmplId for the driver assigned to the passed trip
   */
  private dsrEmployeeIdFromTrip(trip: TripDetail | DispatchTrip): string {
    if ((trip as DispatchTrip).tripInstId) {
      const data = _first(this.getDispatchGridItem([trip as DispatchTrip]));
      return data?.driver?.dsrEmployeeId;
    } else {
      const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
      return data?.dsrEmployeeId;
    }
  }

  updateStateOfBreadcrumbsButton(): void {
    this.breadcrumbsButtonVisible$ = this.trip$.pipe(
      switchMap((trip) => {
        let tripInstId: number;
        let statusCode: string;
        if ((trip as DispatchTrip).tripInstId) {
          tripInstId = (<DispatchTrip>trip)?.tripInstId;
          statusCode = (<DispatchTrip>trip)?.tripStatusCd;
        } else {
          tripInstId = (<TripDetail>trip)?.trip?.tripInstId;
          statusCode = (<TripDetail>trip)?.trip?.statusCd;
        }

        return this.tripBreadcrumbsLayerHelperService.shouldHideOption(BreadcrumbsOptions.SHOW, tripInstId).pipe(
          tap((hideBreadcrumbs: boolean) => {
            hideBreadcrumbs
              ? (this.breadcrumbsText = BreadcrumbsTooltipText.HIDE_BREADCRUMBS)
              : (this.breadcrumbsText = BreadcrumbsTooltipText.SHOW_BREADCRUMBS);
          }),
          map(() => {
            return statusCode !== TripStatusCd.NEW_TRIP && !CartageService.isCartageTrip(trip);
          })
        );
      })
    );
  }

  updateStateOfMessageButton(): void {
    this.messageSate$ = this.trip$.pipe(
      map((trip) => {
        let statusCode: string;
        if ((trip as DispatchTrip)?.tripInstId) {
          const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
          statusCode = data?.tripStatusCd;
        } else {
          const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
          statusCode = data?.statusCd;
        }
        return !CartageService.isCartageTrip(trip) && statusCode !== TripStatusCd.NEW_TRIP;
      })
    );
  }

  updateStateOfDeleteButton(): void {
    const getStops = (tripSummary: TripSummary): Stop[] => {
      return tripSummary?.routeSummary?.reduce((accum: Stop[], route: RouteSummary) => accum.concat(route.stops), []);
    };

    this.isDeleteButtonDisabled$ = combineLatest([this.tripSummary$, this.showSpinner$]).pipe(
      filter(([tripSummary, showSpinner]) => !!tripSummary),
      distinctUntilChanged(([prevTripSummary, prevShowSpinner], [currTripSummary, currShowSpinner]) => {
        const prevStops = getStops(prevTripSummary);
        const currStops = getStops(currTripSummary);
        return prevShowSpinner === currShowSpinner && prevStops.length === currStops.length;
      }),
      map(([tripSummary, showSpinner]) => {
        const stops: Stop[] = getStops(tripSummary).filter(
          (stop: Stop) => stop?.tripNode?.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER
        );
        return stops.length > 0 || showSpinner;
      })
    );

    this.isDeleteButtonVisible$ = this.trip$.pipe(
      map((trip) => {
        if ((trip as DispatchTrip)?.tripInstId) {
          const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));

          return (
            data.tripStatusCd === TripStatusCd.NEW_TRIP ||
            data.tripStatusCd === TripStatusCd.DISPATCHED ||
            data.tripStatusCd === TripStatusCd.RETURNING
          );
        } else {
          const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));

          return (
            data.statusCd === TripStatusCd.NEW_TRIP ||
            data.statusCd === TripStatusCd.DISPATCHED ||
            data.statusCd === TripStatusCd.RETURNING
          );
        }
      })
    );
  }

  updateSateOfDispatchButton(): boolean {
    this.dispatchTripButtonValidationMessage = '';
    this.dispatchTripButtonValidationMessages = [];
    let isTrailerAvailableAndValid: boolean = true;
    (<UntypedFormArray>this.routesAndEquipmmentControl)?.controls?.forEach((control) => {
      const trailer = control.get(TripFormFields.TRAILER);
      if (!(trailer?.valid && !_isEmpty(trailer?.value))) {
        isTrailerAvailableAndValid = false;
      }
    });

    const estClearTimeAvailable: boolean = this.deliveryCountMoreThanZeroSubject.value
      ? !_isEmpty(this.estClearTimeControl.value) && this.estClearTimeControl?.valid
        ? true
        : false
      : true;

    if (!estClearTimeAvailable) {
      this.dispatchTripButtonValidationMessages.push(DispatchTripButtonValidation.EstClearTimeIsValid);
    }

    const estStartTimeIsValid = this.estStartTimeControl?.valid && !_isEmpty(this?.estStartTimeControl.value);

    if (!estStartTimeIsValid) {
      this.dispatchTripButtonValidationMessages.push(DispatchTripButtonValidation.EstStartTimeIsValid);
    }

    const driverIsValid = this.driverControl?.valid && !_isEmpty(this.driverControl?.value);

    if (!driverIsValid) {
      this.dispatchTripButtonValidationMessages.push(DispatchTripButtonValidation.DriverIsValid);
    }

    const tractorIsValid = this.tractorControl?.valid && !_isEmpty(this.tractorControl?.value);

    if (!tractorIsValid) {
      this.dispatchTripButtonValidationMessages.push(DispatchTripButtonValidation.TractorIsValid);
    }

    const straightTruckIsValid = this.straightTruckControl?.valid && this.straightTruckControl.value;

    if (!straightTruckIsValid && !isTrailerAvailableAndValid) {
      const straightTruckOrTrailer: string = `${DispatchTripButtonValidation.StraightTruckIsValid} or ${DispatchTripButtonValidation.TrailerIsAvailableAndValid}`;
      this.dispatchTripButtonValidationMessages.push(straightTruckOrTrailer);
    }

    this.dispatchTripButtonValidationMessage = this.modifyTripDetailsService.createMessageString(
      this.dispatchTripButtonValidationMessages
    );

    return (
      this.showSpinnerSubject.value ||
      !(
        estStartTimeIsValid &&
        driverIsValid &&
        tractorIsValid &&
        estClearTimeAvailable &&
        this.atLeastOneRouteExistSubject.value &&
        (straightTruckIsValid || isTrailerAvailableAndValid)
      )
    );
  }

  updateTheStateOfConvertToCartageButton(): void {
    this.isConvertToCartageButtonEnabled$ = this.trip$.pipe(
      map((trip) => {
        this.convertToCartageButtonText = CartageService.isCartageTrip(trip) ? 'Change Carrier' : 'Convert to Cartage';

        if ((trip as DispatchTrip).tripInstId) {
          const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
          return (
            data?.tripStatusCd === TripStatusCd.NEW_TRIP &&
            data?.routes.length === 1 &&
            (!data.carrierTenderGroupStatusCd ||
              data.carrierTenderGroupStatusCd === CarrierTenderGroupStatusCd.WITHDRAWN) &&
            !this.cartageService.checkEmptyCarrierList()
          );
        } else {
          const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
          return (
            data?.statusCd === TripStatusCd.NEW_TRIP &&
            !!data?.routeInstId &&
            (!data.carrierTenderGroupStatusCd ||
              data.carrierTenderGroupStatusCd === CarrierTenderGroupStatusCd.WITHDRAWN) &&
            !this.cartageService.checkEmptyCarrierList()
          );
        }
      })
    );
  }

  getTripGridItem(trips: TripDetail[]): TripPlanningGridItem[] {
    return this.tripsGridItemConverterService.getTripsGridItems(trips);
  }

  getDispatchGridItem(trips: DispatchTrip[]): DispatcherTripsGridItem[] {
    const sicCode = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    return this.dispatchTripsGridItemConverterService.getDispatchTripsGridItems(sicCode, trips);
  }

  ngOnDestroy() {
    this.modifyTripDetailsService.clearStagedTripsMap();
    this.modifyTripDetailsService.clearStopsFromMap();
    this.unsubscriber.complete();
    this.tripChangesUnsubscriber.complete();
    this.modifyTripDetailsService.updateActivitySuccess$?.pipe(take(1)).subscribe((result) => {
      if (result === true) {
        if (this.layoutPreferenceService.isDispatcherLayout()) {
          this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
        } else {
          this.pndStore$.dispatch(new TripsStoreActions.Refresh());
        }
      }
    });
  }

  private clearForm(): void {
    this.formGroup.reset();

    this.formGroup.get(TripFormFields.AUTO_DISPATCH).setValue(true);
  }

  private subscribeToTripChanges(): void {
    this.tripSummary$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((summ) => {
      this.getTripSummary(summ);
      const allStops: Stop[] = [];
      summ?.routeSummary?.forEach((summary) => {
        const stops: Stop[] = summary?.stops?.filter(
          (stop) =>
            stop.tripNode?.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER &&
            stop.tripNode?.statusCd !== TripNodeStatusCd.PRE_ASSIGNED
        );
        if (_size(stops) > 0) {
          allStops.push(...stops);
        }
      });
      if (
        _size(allStops) > 0 &&
        allStops?.every((stop: Stop) => stop?.tripNode?.statusCd === TripNodeStatusCd.COMPLETED)
      ) {
        this.isReturnButtonDisabledSubject.next(false);
      } else if (_size(allStops) === 0) {
        this.isReturnButtonDisabledSubject.next(false);
      } else {
        this.isReturnButtonDisabledSubject.next(true);
      }

      const actualStart: string = this.timeService.formatDate(this.actualStartTime, 'HH:mm');
      const actualComplete: string = this.timeService.formatDate(this.actualCompleteTime, 'HH:mm');

      if (!_isEmpty(actualStart)) {
        this.formGroup.get(TripFormFields.ACTUAL_START).setValue({
          id: actualStart,
          value: actualStart,
        });

        this.initialFormState?.set(TripFormFields.ACTUAL_START, {
          id: actualStart,
          value: actualStart,
        });
      }

      if (!_isEmpty(actualComplete)) {
        this.formGroup.get(TripFormFields.ACTUAL_COMPLETE).setValue({
          id: actualComplete,
          value: actualComplete,
        });

        this.initialFormState?.set(TripFormFields.ACTUAL_COMPLETE, {
          id: actualComplete,
          value: actualComplete,
        });
      }
    });

    // we only want to update this info when the supplemental trip detail is updated
    this.supplementalTripDetailInfo$
      .pipe(
        tap(() => {
          this.formGroup.disable();
          this.clearForm();
          this.initialFormState = undefined;
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe(
        (tripDetail: TripDetail) => {
          const sic: string = tripDetail.trip.terminalSicCd;
          const tripDate = `${tripDetail.trip.tripDate}T12:00:00.000Z`;

          const tripStatus: TripStatusCd = tripDetail.trip.statusCd;
          this.tripStatus = tripStatus;

          combineLatest([
            this.loadEquipmentDriversAndDispatchAreas(sic, { emptyTrailersOnly: tripStatus !== TripStatusCd.NEW_TRIP }),
            this.loadSuggestedRouteNamesAndEstimatedTimeValues(new Date(tripDate), sic),
          ])
            .pipe(
              take(1),
              switchMap(() => this.serviceCentersService.getSicByCd$(sic))
            )
            .subscribe((serviceCenter) => {
              this.tripChangesUnsubscriber.complete();
              this.tripChangesUnsubscriber = new Unsubscriber();
              let newSicValues: AutoCompleteItem[];

              const sicZonesAndSatellites: SicZonesAndSatellites = this.pndStore$.selectSnapshot(
                GlobalFilterStoreSelectors.globalFilterSicZonesAndSatellites
              );

              // create sic values for sic pull down that available in New Trip
              const sicValues$ = [sicZonesAndSatellites.host, ...sicZonesAndSatellites.zones].map((sicCd: string) => {
                return this.serviceCentersService.getSicByCd$(sicCd).pipe(
                  take(1),
                  map((serviceCenters) => {
                    return this.formatSicWithCityStateCountry(serviceCenters, sicCd);
                  })
                );
              });

              // populate sic pull down
              this.sics$ = forkJoin(sicValues$).pipe(
                map((values: string[]) => {
                  newSicValues = values.map((sicValue) => {
                    return { id: sicValue?.substring(0, 3), value: sicValue };
                  });
                  return [...this.sics, ...newSicValues];
                })
              );

              const containsPlanningRoutes = !!tripDetail.route.find(
                (route) => route?.route?.categoryCd === RouteCategoryCd.PLANNING
              );

              // Enable/disable based on trip/route status
              if (!containsPlanningRoutes && tripStatus !== TripStatusCd.COMPLETED) {
                this.formGroup.enable();
              }

              this.deliveryCountMoreThanZeroSubject.next(
                tripDetail?.route?.some((item) => item?.route?.deliveryStopCount > 0)
              );

              const originNode: TripNode = tripDetail.tripStops
                .map((stop) => stop.tripNode)
                .find((node) => node.tripNodeTypeCd === TripNodeTypeCd.ORIGIN);

              const estStart: string = this.timeService.formatDate(
                originNode?.estimatedDepartDateTime,
                'HH:mm',
                tripDetail.trip.terminalSicCd
              );

              const estClear: string = this.timeService.formatDate(
                tripDetail.trip.estimatedEmptyDateTime,
                'HH:mm',
                tripDetail.trip.terminalSicCd
              );

              const actualStart: string = this.timeService.formatDate(this.actualStartTime, 'HH:mm');

              const actualComplete: string = this.timeService.formatDate(this.actualCompleteTime, 'HH:mm');

              const destinationNode: TripNode = tripDetail.tripStops
                .map((stop) => stop.tripNode)
                .find((node) => node.tripNodeTypeCd === TripNodeTypeCd.DESTINATION);

              const estComplete: string = this.timeService.formatDate(
                destinationNode?.estimatedArriveDateTime,
                'HH:mm',
                tripDetail.trip.terminalSicCd
              );

              this.formGroup.get(TripFormFields.TRIP_INST_ID).setValue(tripDetail.trip.tripInstId);

              this.formGroup.get(TripFormFields.SIC).setValue({
                id: sic,
                value: sic,
              });

              this.formGroup.get(TripFormFields.TRIP_DATE).setValue(tripDate);
              this.formGroup.get(TripFormFields.TRIP_STATUS).setValue(this.tripStatus);

              if (!_isEmpty(estStart)) {
                this.formGroup.get(TripFormFields.EST_START).setValue({
                  id: estStart,
                  value: estStart,
                });
              }

              if (!_isEmpty(estClear)) {
                this.formGroup.get(TripFormFields.EST_CLEAR).setValue({
                  id: estClear,
                  value: estClear,
                });
              }

              if (!_isEmpty(estComplete)) {
                this.formGroup.get(TripFormFields.EST_COMPLETE).setValue({
                  id: estComplete,
                  value: estComplete,
                });
              }

              if (!_isEmpty(actualStart)) {
                this.formGroup.get(TripFormFields.ACTUAL_START).setValue({
                  id: actualStart,
                  value: actualStart,
                });
              }

              if (!_isEmpty(actualComplete)) {
                this.formGroup.get(TripFormFields.ACTUAL_COMPLETE).setValue({
                  id: actualComplete,
                  value: actualComplete,
                });
              }

              if (tripDetail?.dispatchGroup?.groupId) {
                this.formGroup.get(TripFormFields.DISPATCH_AREA).setValue({
                  id: tripDetail.dispatchGroup.groupId.toString(),
                  value: tripDetail.dispatchGroup.groupName,
                });

                this.createTripService.addDispatchArea(tripDetail.dispatchGroup);
              }

              this.formGroup.get(TripFormFields.AUTO_DISPATCH).setValue(!tripDetail.trip.doNotAutoDisplayFlag);

              // Carrier drivers don't have dsrEmployeeId, so we have to add a temporary id to send driver information back to the API
              if (
                tripDetail?.tripDriver &&
                !tripDetail?.tripDriver?.dsrEmployeeId &&
                CartageService.isCartageTrip(tripDetail)
              ) {
                tripDetail.tripDriver.dsrEmployeeId = `CARRIER-${tripDetail.trip.cmsCarrierId}`;
              }

              // Driver & tractor
              if (tripDetail?.tripDriver?.dsrEmployeeId) {
                this.formGroup.get(TripFormFields.DRIVER).setValue({
                  id: tripDetail.tripDriver.dsrEmployeeId,
                  value: tripDetail.tripDriver.dsrName,
                });

                this.createTripService.addDriver(tripDetail.tripDriver);
              } else if (tripDetail?.tripDriver?.dsrName) {
                this.formGroup.get(TripFormFields.DRIVER).setValue({
                  id: undefined,
                  value: tripDetail.tripDriver.dsrName,
                });
                this.createTripService.addDriver(tripDetail.tripDriver);
                this.formGroup.get(TripFormFields.DRIVER).markAsDirty();
              }

              if (tripDetail?.tractorTripEquipment) {
                this.previousSavedTractorExist = true;
                this.formGroup
                  .get(TripFormFields.TRACTOR)
                  .setValue(this.createTripService.getEquipmentId(tripDetail.tractorTripEquipment));

                this.createTripService.addTractor(tripDetail.tractorTripEquipment);
              }

              if (tripDetail?.tripNote) {
                this.formGroup.get(TripFormFields.REMARKS).setValue(tripDetail.tripNote.note);
              }

              // Routes & equipment
              const routesAndEquipments: UntypedFormArray = this.formGroup.get(
                TripFormFields.ROUTES_AND_EQUIPMENT
              ) as UntypedFormArray;
              routesAndEquipments.clear();

              const filteredRouteEquipment = tripDetail.route.filter(
                (route) =>
                  route.trailer?.tripEquipment?.asgmntStatusCd !== TripEquipmentStatusCd.DROPPED &&
                  route.trailer?.tripEquipment?.asgmntStatusCd !== TripEquipmentStatusCd.PLANNED_FOR_HOOK
              );

              if (filteredRouteEquipment.length > 0) {
                this.atLeastOneRouteExistSubject.next(true);
                tripDetail.dolly = _sortBy(tripDetail.dolly, 'tripEquipmentSequenceNbr');

                _forEach(filteredRouteEquipment, (routeDetail: RouteDetail, i: number) => {
                  const trailer: AutoCompleteItem = this.createTripService.getTrailerId({
                    ...new Equipment(),
                    equipmentIdPrefix: routeDetail?.trailer?.tripEquipment?.equipmentIdPrefix,
                    equipmentIdSuffixNbr: routeDetail?.trailer?.tripEquipment?.equipmentIdSuffixNbr,
                    trailerLoad: {
                      ...new TrailerLoad(),
                      trailerLengthFeet: routeDetail?.trailer?.trailerLoad?.trailerLengthFeet,
                      liftgateInd: routeDetail?.trailer?.trailerLoad?.liftgateInd,
                    },
                  });

                  const trailerData = this.createTripService.findTrailerById(trailer);

                  if (_isEmpty(trailer?.data?.statusCd)) {
                    trailer.data.statusCd = trailerData?.trailerLoad?.currentStatus;
                  }

                  if (_isEmpty(trailer?.data?.status)) {
                    trailer.data.status = this.pdoEquipmentStatusPipe.transform(
                      trailerData?.trailerLoad?.currentStatus
                    );
                  }

                  if (_isEmpty(trailer?.data?.length)) {
                    trailer.data.length = `${trailerData?.trailerLoad?.trailerLengthFeet}'`;
                  }

                  if (_isEmpty(trailer?.data?.liftGate)) {
                    trailer.data.liftGate = trailerData?.trailerLoad?.liftgateInd ? 'LG' : '';
                  }

                  if (_isEmpty(trailer?.data?.manufactureYear)) {
                    trailer.data.manufactureYear = trailerData?.mfrYr;
                  }

                  const dolly: AutoCompleteItem =
                    i < filteredRouteEquipment.length
                      ? this.createTripService.getEquipmentId({
                          ...new TripEquipment(),
                          equipmentIdPrefix: tripDetail.dolly[i]?.equipmentIdPrefix,
                          equipmentIdSuffixNbr: tripDetail.dolly[i]?.equipmentIdSuffixNbr,
                        })
                      : { id: '', value: '' };

                  const formGroup: UntypedFormGroup = this.formBuilder.group(
                    this.buildRoutesAndEquipmentSection(
                      false,
                      false,
                      {
                        id: routeDetail.route?.routeInstId.toString(),
                        value: PndRouteUtils.getRouteId({
                          routePrefix: routeDetail.route?.routePrefix,
                          routeSuffix: routeDetail.route?.routeSuffix,
                        }),
                      },
                      {
                        id: '',
                        value: '',
                      },
                      '',
                      trailer,
                      dolly,
                      // strips leading zeroes for display, extra checks to account for bad or corrupted data
                      routeDetail?.route?.plannedDoor && !isNaN(parseInt(routeDetail.route.plannedDoor, 10))
                        ? parseInt(routeDetail.route.plannedDoor, 10).toString()
                        : '',
                      routeDetail?.trailer?.trailerLoad?.evntDoor,
                      routeDetail?.trailer?.trailerLoad?.currentStatus
                    )
                  );

                  if (routeDetail?.trailer?.tripEquipment) {
                    this.createTripService.addTrailer(routeDetail?.trailer?.tripEquipment);
                  }

                  if (tripDetail.dolly[i]) {
                    this.createTripService.addDolly(tripDetail.dolly[i]);
                  }

                  formGroup.get(TripFormFields.IS_IN_EDIT_MODE).setValue(false);
                  routesAndEquipments.push(formGroup);
                });
              }

              this.formGroup.get(TripFormFields.CARRIER_ID).setValue(tripDetail.trip.cmsCarrierId);

              this.formGroup.markAllAsTouched();
              this.subscribeToValueChanges();

              this.checkFieldsAvailable(tripDetail.trip);
              this.initialFormState = this.getFormState();

              if (this.tripStatus === TripStatusCd.RETURNING) {
                this.modifyTripDetailsService.toggleShowTripDetailsSection(!this.formGroup.valid);
              }
              this.showSpinnerSubject.next(false);
            });
        },
        (error) => {
          this.showSpinnerSubject.next(false);
        }
      );
  }

  protected subscribeToValueChanges() {
    super.subscribeToValueChanges();

    const sic: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    this.timeService
      .timezoneForSicCd$(sic)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((timezone) => {
        this.estCompleteTimeControl.valueChanges
          .pipe(
            takeUntil(this.unsubscriber.done$),
            distinctUntilChanged((a, b) => a?.value === b?.value)
          )
          .subscribe((estCompleteTime: { id: string; value: string }) => {
            this.trip$.pipe(take(1)).subscribe((trip) => {
              if ((trip as DispatchTrip).tripInstId) {
                (<DispatchTrip>trip).completeDateTime = this.formatDateTime(
                  (<DispatchTrip>trip).tripDate,
                  timezone,
                  TripFormFields.EST_COMPLETE
                );
              } else {
                (<TripDetail>trip).trip.estimatedEmptyDateTime = this.formatDateTime(
                  (<TripDetail>trip).trip.tripDate,
                  timezone,
                  TripFormFields.EST_COMPLETE
                );
              }
            });
          });
      });
  }

  private getTripSummary(tripSummary: TripSummary): void {
    tripSummary?.routeSummary?.[0]?.stops?.forEach((stop) => {
      if (stop?.tripNode?.tripNodeTypeCd === TripNodeTypeCd.ORIGIN) {
        stop?.activities?.forEach((activity) => {
          activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.DEPART_DISPATCH
            ? (this.actualStartTime = activity?.tripNodeActivity?.actualActivityDateTimeLocal)
            : (this.actualStartTime = undefined);
        });
      }
      if (stop?.tripNode?.tripNodeTypeCd === TripNodeTypeCd.DESTINATION) {
        stop?.activities?.forEach((activity) => {
          activity?.tripNodeActivity?.activityCd === TripNodeActivityCd.ARRIVE
            ? (this.actualCompleteTime = activity?.tripNodeActivity?.actualActivityDateTimeLocal)
            : (this.actualCompleteTime = undefined);
        });
      }
    });
  }

  private checkFieldsAvailable(trip: Trip): void {
    const routesAndEquipments: UntypedFormArray = <UntypedFormArray>(
      this.formGroup.get(TripFormFields.ROUTES_AND_EQUIPMENT)
    );

    const disableControls = (): void => {
      routesAndEquipments?.controls.forEach((control) => {
        control.get(TripFormFields.ROUTE_NAME).disable();
        control.get(TripFormFields.IS_NEW_ROUTE).disable();
        control.get(TripFormFields.LOAD_DOOR).disable();

        if (!control.get(TripFormFields.IS_NEW_ROUTE).value) {
          control.get(TripFormFields.TRAILER).disable();
        }
      });
    };

    switch (trip.statusCd) {
      case TripStatusCd.NEW_TRIP:
        routesAndEquipments?.controls.forEach((control) => {
          control.get(TripFormFields.IS_NEW_ROUTE).setValue(false);
          control.get(TripFormFields.UNLOAD_DOOR).disable();
        });
        break;
      case TripStatusCd.DISPATCHED:
        this.formGroup.get(TripFormFields.TRIP_DATE).disable();
        this.formGroup.get(TripFormFields.SIC).disable();
        this.formGroup.get(TripFormFields.EST_START).disable();
        this.formGroup.get(TripFormFields.AUTO_DISPATCH).disable();
        this.formGroup.get(TripFormFields.DRIVER).disable();
        this.formGroup.get(TripFormFields.ACTUAL_START).disable();
        disableControls();

        this.canAddExistingTrip = false;

        break;
      case TripStatusCd.RETURNING:
        this.formGroup.get(TripFormFields.TRIP_DATE).disable();
        this.formGroup.get(TripFormFields.SIC).disable();
        this.formGroup.get(TripFormFields.EST_START).disable();
        this.formGroup.get(TripFormFields.EST_CLEAR).disable();
        this.formGroup.get(TripFormFields.AUTO_DISPATCH).disable();
        this.formGroup.get(TripFormFields.DRIVER).disable();
        this.formGroup.get(TripFormFields.ACTUAL_START).disable();
        disableControls();

        this.canAddExistingTrip = false;

        break;
      case TripStatusCd.COMPLETED:
        this.formGroup.disable();

        break;
    }

    this.formGroup.updateValueAndValidity();
  }

  private getFormState(): Map<string, AutoCompleteItem | { [key: string]: any }> {
    const formMap = new Map<string, AutoCompleteItem | { [key: string]: any }>();

    for (const [key, formControl] of Object.entries(this.formGroup.controls)) {
      formMap.set(key, formControl.value);
    }

    return formMap;
  }

  pendingChanges(): boolean {
    if (!this.initialFormState) {
      return false;
    }

    const currentFormState = this.getFormState();
    let unchaged = this.initialFormState.size === currentFormState.size;

    if (unchaged) {
      for (const [key, initialObj] of this.initialFormState.entries()) {
        const currentObj = currentFormState.get(key);

        // Autocomplete items - Compare by id and value
        if (initialObj?.id && initialObj?.value) {
          unchaged = unchaged && initialObj?.id === currentObj?.id && initialObj?.value === currentObj?.value;
        } else {
          unchaged = unchaged && _isEqual(initialObj, currentObj);
        }
      }
    }

    return !unchaged;
  }

  update$(showMessage = true, overrideDsrLicenseInd?: boolean, initialRequest?: UpdatePnDTripRqst): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.showSpinnerSubject.next(true);
      this.modifyTripDetailsService.clearStagedTripsMap();

      const sicControl = this.formGroup.get(TripFormFields.SIC);

      const sicCd = sicControl?.value?.hasOwnProperty('id')
        ? (<AutoCompleteItem>sicControl.value).id
        : <string>sicControl?.value;

      this.timeService
        .timezoneForSicCd$(sicCd)
        .pipe(take(1))
        .subscribe(
          (timezone: string) => {
            const pndTrip: PnDTrip = new PnDTrip();
            pndTrip.trip = this.getTripData(timezone);
            pndTrip.tripDriver = this.getDriverData();

            // Remove cmsCarrierId from the dsrEmployeeId when it's carrier
            if (CartageService.isCartageTrip(pndTrip)) {
              if (!pndTrip.tripDriver) {
                pndTrip.tripDriver = { ...new TripDriver() };
              }
              pndTrip.tripDriver.dsrEmployeeId = ''; // Cartage should always blank emp id
              const driverValue = this.formGroup.get(TripFormFields.DRIVER)?.value;
              pndTrip.tripDriver.dsrName = driverValue?.value ?? driverValue;
            }

            pndTrip.tractor = this.createTripService.mapTractor(this.getTractorData());
            if (!pndTrip.tractor) {
              this.previousSavedTractorExist = false;
            }
            pndTrip.tripNote = this.getNoteData();
            pndTrip.dollies = this.createTripService.mapDollies(this.getDolliesData());

            if (this.isStraightTruck()) {
              pndTrip.routeDetails = this.getRouteDetailForStraightTruck();
            } else {
              pndTrip.routeDetails = this.getRouteDetails();
            }

            let request: UpdatePnDTripRqst;

            if (initialRequest) {
              request = initialRequest;
              request.overrideDsrLicenseInd = overrideDsrLicenseInd ?? false;
            } else {
              request = new UpdatePnDTripRqst();
              request.overrideAllSoftErrorsInd = true;
              request.confirmCompleteInd = true;
              request.dispatchGroupId = this.getDispatchAreaData()?.groupId;
              request.pndTrip = pndTrip;
              request.overrideDsrLicenseInd = overrideDsrLicenseInd ?? false;
            }

            // Ensures that door numbers have leading zeroes; extra checks in case of bad or corrupted data
            request?.pndTrip?.routeDetails?.forEach((detail) => {
              if (detail?.route?.plannedDoor && !isNaN(parseInt(detail.route.plannedDoor, 10))) {
                detail.route.plannedDoor = parseInt(detail.route.plannedDoor, 10)
                  .toString()
                  .padStart(4, '0');
              } else {
                detail.route.plannedDoor = '';
              }
            });

            this.cityOperationsService
              .updatePnDTrip(request)
              .pipe(
                take(1),
                tap((response: UpdatePnDTripResp) => {
                  const driverWarning =
                    response?.warnings?.find((warning) => warning.errorCd === 'SCOP030-801W') ?? false;
                  if (!driverWarning) {
                    this.modifyTripDetailsService.setTrip(response.tripDetail, false);
                  }
                }),
                switchMap((response) => {
                  const driverWarning =
                    response?.warnings?.find((warning) => warning.errorCd === 'SCOP030-801W') ?? false;
                  if (driverWarning) {
                    const confirmConfig: XpoConfirmDialogConfig = {
                      confirmButtonText: 'Yes',
                      rejectButtonText: 'No',
                      icon: 'warning',
                      showCancelButton: true,
                    };
                    return this.confirmDialog.confirm(driverWarning.message, 'Driver License', confirmConfig).pipe(
                      switchMap((result) => {
                        if (result) {
                          return this.update$(showMessage, true, request);
                        } else {
                          this.showSpinnerSubject.next(false);

                          subscriber.next();
                          subscriber.complete();
                          return EMPTY;
                        }
                      })
                    );
                  } else {
                    // need to also update supplemental trip detail info after setting trip since we don't want to do a
                    // complete refresh of the component but need the data from supplemental info for the panel
                    return this.modifyTripDetailsService.updateSupplementalTripDetailsForTrip$(pndTrip.trip.tripInstId);
                  }
                })
              )
              .subscribe(
                () => {
                  if (showMessage) {
                    this.notificationMessageService
                      .openNotificationMessage(NotificationMessageStatus.Success, 'Trip updated successfully!')
                      .subscribe(() => {});
                  }

                  this.showSpinnerSubject.next(false);
                  this.formGroup.markAsPristine();

                  subscriber.next();
                  subscriber.complete();
                },
                (error) => {
                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Error, error)
                    .subscribe(() => {});
                  this.showTripDetailsSection$.pipe(take(1)).subscribe((isOpen: boolean) => {
                    if (!isOpen) {
                      this.expandTripDetails();
                    }
                  });
                  this.showSpinnerSubject.next(false);

                  subscriber.error('Error updating the trip');
                  subscriber.complete();
                }
              );
          },
          (error) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});
            this.showTripDetailsSection$.pipe(take(1)).subscribe((isOpen: boolean) => {
              if (!isOpen) {
                this.expandTripDetails();
              }
            });
            this.showSpinnerSubject.next(false);

            subscriber.error('Error updating the trip');
            subscriber.complete();
          }
        );
    });
  }

  saveChanges(showMessage = true): Promise<void> {
    return new Promise((resolve) => {
      this.update$(showMessage).subscribe(() => {
        if (this.layoutPreferenceService.isDispatcherLayout()) {
          this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
          this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
          resolve();
        } else {
          this.pndStore$.dispatch(new TripsStoreActions.Refresh());
          this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
          resolve();
        }
      });
    });
  }

  dispatch(): void {
    if (this.pendingChanges() && this.formGroup.valid) {
      this.saveChanges(false).then(() => {
        this.dispatchButtonClickSubject.next();
      });
    } else {
      this.showSpinnerSubject.next(true);
      this.dispatchButtonClickSubject.next();
    }
  }

  dispatchButtonClicked(trip: TripDetail | DispatchTrip) {
    let date: string;
    let tripInstanceId: number;

    if ((trip as DispatchTrip).tripInstId) {
      const tripToDispatch = trip as DispatchTrip;
      date = tripToDispatch?.tripDate;
      tripInstanceId = tripToDispatch?.tripInstId;
    } else {
      const tripToDispatch = trip as TripDetail;
      date = tripToDispatch?.trip?.tripDate;
      tripInstanceId = tripToDispatch?.trip?.tripInstId;
    }

    const sic: string = this.pndStore$.selectSnapshot(GlobalFilterStoreSelectors.globalFilterSic);
    const tripDate: string = date;

    const timezone$: Observable<string> = this.timeService.timezoneForSicCd$(sic);

    this.dispatcherTripsService
      .isTodaysDateTrip$(tripDate, timezone$)
      .pipe(take(1))
      .subscribe((isTodaysDayTripObj: { isTodaysDayTrip: boolean; days: number }) => {
        if (!isTodaysDayTripObj.isTodaysDayTrip) {
          this.dispatcherTripsService
            .confirmIsFutureOrPastTripDispatch$(tripDate, isTodaysDayTripObj.days)
            .pipe(take(1))
            .subscribe((dispatchAnyway: boolean) => {
              if (dispatchAnyway) {
                this.dispatchAction(tripInstanceId);
              } else {
                this.showSpinnerSubject.next(false);
              }
            });
        } else {
          this.dispatchAction(tripInstanceId);
        }
      });
  }

  private dispatchAction(tripInstanceId: number): void {
    this.isProcessingSubject.next(true);
    this.dispatcherTripsService
      .dispatchTrip$(tripInstanceId, new Date())
      .pipe(
        switchMap((isDispatched: boolean) => {
          if (isDispatched) {
            return this.modifyTripDetailsService.fetchTripDetailsForTrip$(tripInstanceId);
          } else {
            return of(undefined);
          }
        }),
        finalize(() => {
          this.isProcessingSubject.next(false);
          this.showSpinnerSubject.next(false);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((updatedTrip: TripDetail) => {
        if (updatedTrip) {
          this.modifyTripDetailsService.setTrip(updatedTrip, false);
          this.refreshUpdateTripPanel();
        } else {
          this.showTripDetailsSection$.pipe(take(1)).subscribe((isOpen: boolean) => {
            if (!isOpen) {
              this.expandTripDetails();
            }
          });
        }
      });
  }

  deleteButtonClicked(trip: TripDetail | DispatchTrip): void {
    this.isProcessingSubject.next(true);
    let tripInstId;

    if ((trip as DispatchTrip).tripInstId) {
      const dispatchTrip: DispatchTrip = trip as DispatchTrip;
      tripInstId = dispatchTrip.tripInstId;
    } else {
      const tripDetail: TripDetail = trip as TripDetail;
      tripInstId = tripDetail.trip.tripInstId;
    }

    this.dispatcherTripsService
      .deleteTrip$(tripInstId)
      .pipe(
        finalize(() => {
          this.isProcessingSubject.next(false);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((isDeleted) => {
        const criteria = this.pndStore$.selectSnapshot(DispatcherTripsStoreSelectors.searchCriteria);
        this.pndStore$.dispatch(new DispatcherTripsStoreActions.SetSearchCriteria({ criteria: criteria }));
        if (isDeleted) {
          this.close(false);
        }
      });
  }

  delete(): void {
    this.deleteButtonClickSubject.next();
  }

  convertToCartageButtonClicked(trip: TripDetail | DispatchTrip): void {
    let routeInstId: string;
    let cmsCarrierId: number;

    if ((trip as DispatchTrip).tripInstId) {
      const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
      routeInstId = data?.routes[0]?.uniqRouteId;
      cmsCarrierId = data?.cmsCarrierId;
    } else {
      const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
      routeInstId = data?.routeInstId?.toString();
      cmsCarrierId = data?.cmsCarrierId;
    }

    this.cartageService.carrierMasterLocations$.pipe(take(1)).subscribe((carrierLocations: CarrierMasterLocation[]) => {
      const convertRouteCallback$: (carrierId: number, dispatchType: CmsTripTypeCd) => Observable<void> = (
        carrierId,
        dispatchType
      ) => {
        return this.cartageService.convertRoute$(+routeInstId, carrierId, dispatchType).pipe(
          switchMap(() => {
            return this.modifyTripDetailsService.fetchTripDetailsForTrip$(
              (<DispatchTrip>trip).tripInstId ?? (<TripDetail>trip).trip?.tripInstId
            );
          }),
          tap((updatedTripDetail: TripDetail) => {
            this.modifyTripDetailsService.setTrip(updatedTripDetail, false);
            this.refreshUpdateTripPanel();
          }),
          mapTo(undefined)
        );
      };

      const carriers = carrierLocations.map((c) => c.carrierMaster);

      this.dialog.open(CarriersSelectorDialogComponent, {
        data: {
          currentCarrier: {
            ...new CarrierMaster(),
            carrierId: cmsCarrierId,
          },
          carriers,
          convertRouteCallback$,
        },
        disableClose: false,
        hasBackdrop: true,
      });
    });
  }

  convertToCartage(): void {
    this.convertToCartageButtonClickSubject.next();
  }

  showBreadcrumbsButtonClicked(trip: TripDetail | DispatchTrip): void {
    let tripInstId: number;
    if ((trip as DispatchTrip).tripInstId) {
      tripInstId = (<DispatchTrip>trip)?.tripInstId;
    } else {
      tripInstId = (<TripDetail>trip)?.trip?.tripInstId;
    }
    if (this.breadcrumbsText === BreadcrumbsTooltipText.SHOW_BREADCRUMBS) {
      this.tripRenderingService.showBreadcrumbs(tripInstId);
    }

    if (this.breadcrumbsText === BreadcrumbsTooltipText.HIDE_BREADCRUMBS) {
      this.tripRenderingService.hideBreadcrumbs(tripInstId);
    }
    this.updateStateOfBreadcrumbsButton();
  }

  showBreadcrumbs(): void {
    this.showBreadCrumbsButtonClickSubject.next();
  }

  /**
   * Open the Messaging Dialog for the specified contact at the position
   */
  openMessageDialog(driverContact: XpoLtlDriverContact, position: { x: number; y: number }): void {
    if (driverContact) {
      // ensure we have a valid position
      const dialogPos =
        position?.x && position?.y
          ? {
              left: `${position?.x + 250}px`,
              top: `${position?.y - 200}px`,
            }
          : undefined;

      this.messagingDialog.open({
        title: 'Driver Messaging',
        position: dialogPos,
        driverContact,
      });
    }
  }

  onSendMessage(event: MouseEvent): void {
    this.messagesButtonClickSubject.next(event);
  }

  returnTripButtonClicked(trip: TripDetail | DispatchTrip): void {
    let tripInstId: number;
    let tripComplteDate: Date;

    if ((trip as DispatchTrip).tripInstId) {
      const data: DispatchTrip = trip as DispatchTrip;
      tripInstId = data?.tripInstId;
      tripComplteDate = data?.completeDateTime;
    } else {
      const data: TripDetail = trip as TripDetail;
      tripInstId = data?.trip?.tripInstId;
      tripComplteDate = data?.trip?.estimatedEmptyDateTime;
    }

    this.dispatcherTripsService
      .returnTrip$(tripInstId, tripComplteDate)
      .pipe(
        finalize(() => {
          this.isProcessingSubject.next(false);
          this.showSpinnerSubject.next(false);
        }),
        switchMap((success: boolean) => {
          return success ? this.modifyTripDetailsService.fetchTripDetailsForTrip$(tripInstId) : of(undefined);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((updatedTrip: TripDetail) => {
        if (updatedTrip) {
          this.modifyTripDetailsService.setTrip(updatedTrip, false);
          this.refreshUpdateTripPanel();
        } else {
          this.expandTripDetails();
        }
      });
  }

  returnTrip(): void {
    if (this.pendingChanges() && this.formGroup.valid) {
      this.saveChanges(false).then(() => {
        this.returnTripButtonClickSubject.next();
      });
    } else {
      this.showSpinnerSubject.next(true);
      this.returnTripButtonClickSubject.next();
    }
  }

  completeTrip(): void {
    this.showSpinnerSubject.next(true);
    this.completeTripButtonClickSubject.next();
  }

  completeTripButtonClicked(trip: TripDetail | DispatchTrip): void {
    let tripInstId: number;

    if ((trip as DispatchTrip).tripInstId) {
      const data: DispatcherTripsGridItem = _first(this.getDispatchGridItem([trip as DispatchTrip]));
      tripInstId = data?.tripInstId;
    } else {
      const data: TripPlanningGridItem = _first(this.getTripGridItem([trip as TripDetail]));
      tripInstId = data?.tripInstId;
    }

    this.dispatcherTripsService
      .completeTrip$(tripInstId, moment().toDate())
      .pipe(
        finalize(() => {
          this.isProcessingSubject.next(false);
          this.showSpinnerSubject.next(false);
        }),
        switchMap((success: boolean) => {
          return success ? this.modifyTripDetailsService.fetchTripDetailsForTrip$(tripInstId) : of(undefined);
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((updatedTrip: TripDetail) => {
        if (updatedTrip) {
          this.modifyTripDetailsService.setTrip(updatedTrip, false);
          this.refreshUpdateTripPanel();
        } else {
          this.expandTripDetails();
        }
      });
  }

  private refreshUpdateTripPanel(): void {
    if (this.layoutPreferenceService.isDispatcherLayout()) {
      this.pndStore$.dispatch(new DispatcherTripsStoreActions.Refresh());
    } else {
      this.pndStore$.dispatch(new TripsStoreActions.Refresh());
    }
    this.pndStore$.dispatch(new ModifyTripDetailsActions.Refresh());
  }

  private formatSicWithCityStateCountry(serviceCenter: ServiceCenter, sicCd: string): string {
    if (
      !_isEmpty(serviceCenter?.locAddress?.cityName) &&
      !_isEmpty(serviceCenter?.locAddress?.countrySubdivisionName)
    ) {
      const cityName = serviceCenter?.locAddress?.cityName.replace(
        /\w\S*/g,
        (text) => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
      );
      const states = _capitalize(serviceCenter?.locAddress?.countrySubdivisionName);

      return sicCd.concat(' - ', cityName.concat(', ', states));
    } else {
      return undefined;
    }
  }

  hideRouteGrid({ routesGridReady, showSpinner }): boolean {
    return !routesGridReady || showSpinner;
  }

  private expandTripDetails(): void {
    this.modifyTripDetailsService.toggleShowTripDetailsSection(!this.formGroup.valid);
  }
}
