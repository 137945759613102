import { XpoSelectFilter, XpoSelectFilterParams } from '@xpo-ltl/ngx-board/core';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

export class SpecialServiceFilter extends XpoSelectFilter {
  constructor(field: string) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Special Services',
      options: [
        { title: 'Appointment', code: ShipmentSpecialServiceCd.APPOINTMENT },
        { title: 'Construction Delivery', code: ShipmentSpecialServiceCd.CONSTRUCTION_AND_UTILITY_SITES_DELIVERY },
        { title: 'Customs In-Bond', code: ShipmentSpecialServiceCd.CUSTOMS_IN_BOND },
        { title: 'Driver Collect', code: ShipmentSpecialServiceCd.DRIVER_COLLECT },
        { title: 'Excessive Length', code: ShipmentSpecialServiceCd.EXCESSIVE_LENGTH },
        { title: 'Food', code: ShipmentSpecialServiceCd.FOOD },
        { title: 'Freezable', code: ShipmentSpecialServiceCd.FREEZABLE },
        { title: 'G12', code: ShipmentSpecialServiceCd.GUARANTEED_BY_NOON },
        { title: 'Guaranteed', code: ShipmentSpecialServiceCd.GUARANTEED },
        { title: 'Hazmat', code: ShipmentSpecialServiceCd.HAZMAT },
        { title: 'LiftGate', code: ShipmentSpecialServiceCd.LIFT_GATE },
        { title: 'Notify On Arrival', code: ShipmentSpecialServiceCd.NOTIFY_ON_ARRIVAL },
        { title: 'Pup Only', code: ShipmentSpecialServiceCd.PUP_ONLY },
        { title: 'Residential Delivery', code: ShipmentSpecialServiceCd.RESIDENTIAL_DELIVERY },
        { title: 'RRS', code: ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE },
        // { title: 'Retail', code: ShipmentSpecialServiceCd.RETAIL },
        { title: 'Premium', code: ShipmentSpecialServiceCd.PREMIUM },
        { title: 'Trap', code: ShipmentSpecialServiceCd.TRAP_SERVICE },
        { title: 'Show', code: ShipmentSpecialServiceCd.SHOW },
        { title: 'Live', code: ShipmentSpecialServiceCd.LIVE },
        { title: 'LTD', code: ShipmentSpecialServiceCd.LIMITED_ACCESS },
        { title: 'RPD', code: ShipmentSpecialServiceCd.REMOVAL_OF_PALLET_AND_DEBRIS },
      ],
    });
  }
}
